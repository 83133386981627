.robot {
    position: relative;
    width: 200px;
    height: 300px;
    animation: float 4s infinite ease-in-out;
    pointer-events: none;
    
  }
  
  .robot-body {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .robot-head {
    position: absolute;
    bottom: 140px;
    left: 50%;
    transform-origin: center bottom;
    animation: tilt 8s infinite;
  }
  
  @keyframes tilt {
    0% {
      transform: translateX(-50%) rotate(0deg);
    }
    5%, 95% {
      transform: translateX(-50%) rotate(-2deg);
    }
    50%, 100% {
      transform: translateX(-50%) rotate(2deg);
    }
  }



  /* not working */

  
@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5%);
    }
  }
  
  .robot:hover {
    animation-play-state: paused;
  }
  
  .robot-head.talking .head-image-2 {
    opacity: 0;
    animation: fadeImage 0.5s infinite alternate 0.2s;
  }
  
  @keyframes fadeImage {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  
  .head-image {
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .head-image-1 {
    opacity: 1;
    transform: translate(0%, 100%);
  }



  @keyframes enterFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .enter-from-left {
    animation: enterFromLeft 1s ease-out forwards;
  }

  .no-move {
    animation: none
  }
  