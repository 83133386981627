/* This CSS would go within the iframe's content, not the parent page */
html, body {
    background-color: transparent !important;
    margin: 0;
    padding: 0;
}

/* widget.css */

.card-custom {
    max-width: 60%; /* Default max-width */
    margin: 0 auto; /* Center the card */
}

@media (max-width: 500px) {
    .card-custom {
        max-width: 100%; /* Allow the card to fill the width for narrow viewports */
    }
}
