.badge-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3% solid;
    box-sizing: border-box;
}

.special {
    background: radial-gradient(circle at center, transparent 70%, currentColor 70%);
}

.icon {
    width: 70%;
    width:70%;
    z-index: 2;
}
.star {

width:110%;
height:110%;
z-index: 1;
margin-left: -90%;
    /* font-size: 7rem;
    position: absolute;
    top: -15%;
    right: -15%;
     */
}