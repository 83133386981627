body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

}

code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
                monospace;
}

pre {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif !important;
        /* Or the font-family you are using for your <p> tags */
        font-size: 1em !important;
        /*  Adjust as needed to match your <p> tags */
        text-align: left;
        line-height: normal;
        /* Or the line-height you are using for your <p> tags */
        margin: 1em 0;
        /* Adjust margin to match your <p> tags, typical values might be 1em 0 */
        white-space: pre-wrap;
        /* This will wrap text as in <p> but preserve formatting and spaces */
        word-wrap: break-word;
        /* To prevent horizontal scrolling */
        overflow: auto;
        /* Adds a scrollbar if content overflows */
}

.area-home-icon {
        width: 100px;
}

@media (max-width: 767px) {
        .area-home-icon {
                width: 200px;
        }

        .hidden-sm {
                display: none;
        }

        button.btn {
                font-size: 1.5rem;
        }

        .characteristic-tab {
                padding-top: 2rem;
        }



}

.align-text-left {
        text-align: left;
}

.bigger-on-hover {
        cursor: pointer;
}

.bigger-on-hover:hover {
        transform: scale(1.05);
        /* Scales up the div by 5% on hover */
}


.area-home-icon:hover,
.area-page-icon:hover {
        -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
        animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

.area-page-image {
        width: 100%;
        max-width: 250px;
}

.jumbotron {
        background-color: white;
}

.slide-in-blurred-right {
        -webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@-webkit-keyframes slide-in-blurred-right {
        0% {
                -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
                transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
                -webkit-transform-origin: 0% 50%;
                transform-origin: 0% 50%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
        }

        100% {
                -webkit-transform: translateX(0) scaleY(1) scaleX(1);
                transform: translateX(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
        }
}

@keyframes slide-in-blurred-right {
        0% {
                -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
                transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
                -webkit-transform-origin: 0% 50%;
                transform-origin: 0% 50%;
                -webkit-filter: blur(40px);
                filter: blur(40px);
                opacity: 0;
        }

        100% {
                -webkit-transform: translateX(0) scaleY(1) scaleX(1);
                transform: translateX(0) scaleY(1) scaleX(1);
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                -webkit-filter: blur(0);
                filter: blur(0);
                opacity: 1;
        }
}



@-webkit-keyframes pulsate-fwd {
        0% {
                -webkit-transform: scale(1);
                transform: scale(1);
        }

        50% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
        }

        100% {
                -webkit-transform: scale(1);
                transform: scale(1);
        }
}

@keyframes pulsate-fwd {
        0% {
                -webkit-transform: scale(1);
                transform: scale(1);
        }

        50% {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
        }

        100% {
                -webkit-transform: scale(1);
                transform: scale(1);
        }
}



.slide-in-elliptic-top-fwd {
        -webkit-animation: bounce-in-top 1.1s both;
        animation: bounce-in-top 1.1s both;
}

@-webkit-keyframes bounce-in-top {
        0% {
                -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
                opacity: 0;
        }

        38% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                opacity: 1;
        }

        55% {
                -webkit-transform: translateY(-65px);
                transform: translateY(-65px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        72% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        81% {
                -webkit-transform: translateY(-28px);
                transform: translateY(-28px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        90% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        95% {
                -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }
}

@keyframes bounce-in-top {
        0% {
                -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
                opacity: 0;
        }

        38% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                opacity: 1;
        }

        55% {
                -webkit-transform: translateY(-65px);
                transform: translateY(-65px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        72% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        81% {
                -webkit-transform: translateY(-28px);
                transform: translateY(-28px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        90% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        95% {
                -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }
}


.hidden {
        display: none !important;
}

html {
        background-color: #e9ecef;
}

.app {
        background-color: #e9ecef;
        min-height: 100vh;
}






/* The Modal (background) */
.my-modal {
        /*display: none; /* Hidden by default */
        padding: 3rem;
        position: fixed;
        /* Stay in place */
        z-index: 500;
        /* Sit on top */
        padding-top: 1rem;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100% !important;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: none;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
}

/* Modal Content */
.my-modal-content {
        position: relative;
        background-color: white;
        margin: auto;
        padding: 0;
        border: 1px solid #888;
        width: 100%;
        height: 90vh;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        display: block !important;
}


.my-modal-header {
        padding: 2px 16px;
        background-color: #2c7ec2;
        color: white !important;
        display: block !important;
}

.my-modal-body {
        padding: 2px 16px;
        height: 80vh;
        overflow: auto;
}

.my-modal-footer {
        padding: 2px 16px;

}

/* Add Animation */
@-webkit-keyframes animatetop {
        from {
                top: -300px;
                opacity: 0
        }

        to {
                top: 0;
                opacity: 1
        }
}

@keyframes animatetop {
        from {
                top: -300px;
                opacity: 0
        }

        to {
                top: 0;
                opacity: 1
        }
}

/* The Close Button */
.close {
        color: white;
        float: right;
        font-size: 24px;
        font-weight: bold;
}

.close:hover,
.close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
}




/*quiz*/

.quizChoice {
        padding: 20px;
}

.quizChoice:hover {
        -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
        animation: pulsate-fwd 0.5s ease-in-out infinite both;
}



/*teacher*/
.teacher-icon-card {
        padding: 20px;
}

.inclusion-field {
        border: 1px solid #dfdfdf;
        text-align: center;
}

.feedback-left {
        height: 70vh;
        overflow: auto;
}



.scrollable-card {
        max-height: 50vh;
        overflow-y: auto;
        padding-right: 15px;
        /* To avoid content being hidden behind scrollbar */
}

/* sign in form */

.landing-page {
        padding: 5rem;
        background-color: #2E7DC1;
        text-align: center;
        min-height: 100vh;
}

.form-field {
        margin-top: 10px;
}

.sign-in-form {
        padding: 5rem;
        padding-top: 1rem;
}

@media (max-width: 767px) {
        .sign-in-form {
                padding: 1rem;
                /* Set the padding to 1rem on mobile devices */
                font-size: 1.5rem;
                /* Increase the font size for better readability on mobile */
        }
}


/* table/grid */

.table-fixed-head {
        overflow: auto;
        max-height: 20rem;
}

.table-fixed-head thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
}

.table-fixed-head td {
        min-width: 8rem;
}

/*fix broken bootstrap*/

.row {
        margin-right: 0px !important;
        margin-left: 0px !important;
}

.nav-link {
        cursor: pointer;
}



/* Tooltip container */
.tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
        /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
        visibility: visible;
}

/* characteristics */
.characteristic-body {
        border-style: solid;
        border-width: 2px;
        border-color: gray;
        padding: 1rem;
        border-radius: 2rem;
        /* Set the border radius to 10 pixels for the top left and bottom right corners only */
        border-top-left-radius: 2rem;
        border-bottom-right-radius: 2rem;

        /* Set the border radius to 20 pixels for the top right and bottom left corners only */
        border-top-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
}

p.bubble {
        position: relative;
        width: 100%;
        text-align: center;
        line-height: 1.4em;
        margin: 40px auto;
        background-color: #fff;
        border: 8px solid #333;
        border-radius: 30px;
        font-family: sans-serif;
        padding: 20px;
        font-size: large;
}

p.bubble:before,
p.bubble:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
}

p.speech:before {
        top: calc(50% - 25px);
        left: -50px;

        border: 25px solid;
        border-color: transparent #333 transparent transparent;
}

p.speech:after {
        top: calc(50% - 15px);
        left: -30px;
        border: 15px solid;
        border-color: transparent #fff transparent transparent;
}


.confetti-wrapper {
        z-index: 500;
}

.badge-visible {
        animation: slideIn 3s forwards;
        /* Adjust the duration (3s) as per the animation duration above */
}

.badge-hidden {
        animation: slideOut 1s forwards;
        /* Adjust the duration (1s) as per the animation duration above */
}

@keyframes slideIn {
        0% {
                transform: translateX(-100%);
        }

        100% {
                transform: translateX(0);
        }
}

@keyframes slideOut {
        0% {
                transform: translateX(0);
        }

        100% {
                transform: translateX(-100%);
        }
}

.overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9998;
}

.centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
}

@media print {

        .no-print,
        .no-print * {
                display: none !important;
        }

        .text-light-color {
                color: #6c757d !important;
        }

        .page-break {
                break-after: page;
        }
}


/* teacher nav */

.school-nav-text {
        /* Your custom styles here to align text with the bottom of the image and add padding to the left */
        display: inline-block;
        /* To make the div inline with the image */
        vertical-align: bottom;
        /* Align the div's content to the bottom of the image */
        padding-left: 10px;
        /* Add left padding to the div */

        /* Hide the div on mobile devices */
        display: block;
        /* Reset the display property */
}

/* Media query to hide the school div on mobile */
@media (max-width: 767px) {
        .school-text {
                display: none;
                /* Hide the div on mobile devices */
        }

        .nav-name {
                display: none;
        }
}



.chart-container {
        width: 100%;
        max-width: 600px;
        /* Example max-width for responsiveness */
        height: auto;
        /* Adjust based on your design */
        aspect-ratio: 1;
        /* Keeps it square, remove or adjust as needed */
}

.card-shadow {
        /* Add your other styles for the card here (width, height, background, etc.) */

        /* Example shadow: 5px right, 10px down, 0 blur, 0 spread, black color */
        box-shadow: 2px 4px 5px 5px rgba(0, 0, 0, 0.658);
}

.card-dashboard {
        padding: 1rem;
        background-color: #f8fcff;
}

.typing {
        align-items: center;
        display: flex;
        height: 17px;
}

.typing .dot {
        animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
        background-color: #6CAD96;
        border-radius: 50%;
        height: 7px;
        margin-right: 4px;
        vertical-align: middle;
        width: 7px;
        display: inline-block;
}

.typing .dot:nth-child(1) {
        animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
        animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
        animation-delay: 400ms;
}

.typing .dot:last-child {
        margin-right: 0;
}

@keyframes mercuryTypingAnimation {
        0% {
                transform: translateY(0px);
                background-color: #6CAD96;
        }

        28% {
                transform: translateY(-7px);
                background-color: #9ECAB9;
        }

        44% {
                transform: translateY(0px);
                background-color: #B5D9CB;
        }
}







.report-container-pdf {
        /* General container styling */
        font-family: 'Arial', sans-serif;
        /* Clean, professional font */
        font-size: 1.3em;
        /* Scale everything proportionally */
        color: #333;
        /* Dark gray for text for better readability */
        line-height: 1.6;
        /* Good line spacing for readability */
        border: none;
        /* Remove borders */
        margin: 0;
        /* No margins for document-style layout */
        max-width: none;
        /* Remove width restriction */
        overflow: visible;
        /* Ensure all content is shown */
}

/* Headings styling */
.report-container-pdf h1 {
        color: #2c3e50;
        /* Darker blue-gray for primary headings */
        font-weight: bold;
        margin-top: 1.5em;
        /* Add space above headings */
        margin-bottom: 0.75em;
        /* Space below headings */
}

.report-container-pdf h2 {
        color: #34495e;
        /* A lighter blue-gray for secondary headings */
        font-weight: bold;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
}

.report-container-pdf h3 {
        color: #5d6d7e;
        /* A softer gray for tertiary headings */
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 0.5em;
}

.report-container-pdf h4 {

        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 0.5em;
}

/* Tables styling */
.report-container-pdf table {
        width: 100%;
        /* Tables take full width of the container */
        border-collapse: collapse;
        /* Remove gaps between table cells */
        margin: 1.5em 0;
        /* Space around tables */
        font-size: inherit;
        /* Use container's font size */
}

.report-container-pdf table th,
.report-container-pdf table td {
        border: 1px solid #ccc;
        /* Light border for table cells */
        padding: 0.3em;
        /* Space inside table cells */
        text-align: left;
        /* Align text to the left */
        font-size: 0.7em;
}

.report-container-pdf table th {
        background-color: #e9ecef;
        /* Light background for table headers */
        color: #2c3e50;
        /* Darker text color for headers */
        font-weight: bold;
}

/* Links styling */
.report-container-pdf a {
        color: #007bff;
        /* Blue links for emphasis */
        text-decoration: none;
}

.report-container-pdf a:hover {
        text-decoration: underline;
        /* Underline on hover for clarity */
}

/* Miscellaneous */
.report-container-pdf img {
        max-width: 100%;
        /* Ensure images fit within the container */
        margin-bottom: 1.5em;
        /* Space below images */
}

.report-container-pdf hr {
        border: 0;
        border-top: 1px solid #ddd;
        margin: 2em 0;
        /* Space above and below horizontal rules */
}

/* Print-specific styling */
@media print {
        .report-container-pdf {
                margin: 10px;
                /* Add padding for print layout */
                page-break-inside: avoid;
                /* Avoid breaking inside containers */
        }

        .report-container-pdf table {
                page-break-inside: avoid;
                /* Prevent table from breaking inside */
        }

        .converted-chart-container {
                page-break-inside: avoid;
                /* Prevent table from breaking inside */
        }



        /* Start each section on a new page */
        .report-container-pdf:not(:first-of-type) {
                page-break-before: always;
                /* Force a new page for each section */
        }

        /* Hide buttons during printing */
        button,
        .generate-report-btn,
        .print-report-btn {
                display: none !important;
        }
}

@page {
        margin: 3cm 1cm;
        /* 2cm top and bottom, 1cm left and right */
}

/* Bar Chart Container */
.converted-chart-container {
        background: #f5f5f5;
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
}

/* Each Bar Group */
.converted-bar-group {
        flex: 1;
        /* Ensure all groups take equal width */
        display: flex;
        flex-direction: column;
        align-items: center;
}

/* Bar Section */
.converted-bar-section {
        height: 300px;
        /* Fixed height for the bar area */
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 1rem;
}

/* Bar Styles */
.converted-bar {
        width: 40px;
        /* Fixed bar width */
        background-color: #2563eb;
        border-radius: 4px;
        border: 1px solid #1e40af;
        transition: height 0.3s ease;
}

/* Value Above the Bar */
.converted-bar-value {
        font-size: 14px;
        margin-bottom: 0.5rem;
        color: #000;
        text-align: center;
}

/* Label Section */
.converted-label-section {
        width: 100%;
        /* Take full width of the group */
        text-align: center;
        font-weight: 500;
        color: #000;
        white-space: pre-wrap;
        /* Ensure wrapping of long text */
        margin-top: 0.5rem;
}

/* Print-Specific Styles */
@media print {
        .converted-chart-container {
                padding: 1rem;
                /* Reduce padding for better fit */
        }

        .converted-bar-section {
                height: auto;
                /* Allow flexible height for scaling */
        }

        .converted-bar {
                width: 20px;
                /* Reduce bar width for printing */
        }

        .converted-bar-value {
                font-size: 10px;
                /* Reduce font size for values */
        }

        .converted-label-section {
                font-size: 12px;
                /* Adjust label font size */
        }
}


/* Bar Chart Container */
.converted-chart-container {
        background: #f5f5f5;
        padding: 2rem;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
}

/* Each Bar Group */
.converted-bar-group {
        flex: 1;
        /* Ensure all groups take equal width */
        display: flex;
        flex-direction: column;
        align-items: center;
}

/* Bar Section */
.converted-bar-section {
        height: 300px;
        /* Fixed height for the bar area */
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 1rem;
}

/* Bar Styles */
.converted-bar {
        width: 40px;
        /* Fixed bar width */
        background-color: #2563eb;
        border-radius: 4px;
        border: 1px solid #1e40af;
        transition: height 0.3s ease;
}

/* Value Above the Bar */
.converted-bar-value {
        font-size: 14px;
        margin-bottom: 0.5rem;
        color: #000;
        text-align: center;
}

/* Label Section */
.converted-label-section {
        width: 100%;
        /* Take full width of the group */
        text-align: center;
        font-weight: 500;
        color: #000;
        white-space: pre-wrap;
        /* Ensure wrapping of long text */
        margin-top: 0.5rem;
}

/* Print-Specific Styles */
@media print {
        .converted-chart-container {
                padding: 1rem;
                /* Reduce padding for better fit */
        }

        .converted-bar-section {
                height: 100px;
                /* Set height to 50% of the original */
        }

        .converted-bar {
                width: 20px;
                /* Reduce bar width for printing */
        }

        .converted-bar-value {
                font-size: 12px;
                /* Reduce font size for values */
        }

        .converted-label-section {
                font-size: 12px;
                /* Adjust label font size */
        }
}